<template>
  <v-card height="400px" color="#273142">
    <v-card-title class="card-header">
      <h3 class="ml-3">{{ title }}</h3>
    </v-card-title>

    <v-card-text class="mt-3">
       <PlaceHolder
        :src="require('@/assets/img/svg/no-notifications.svg')"
        title="No bids placed at the moment."
        text=""
        v-if="orders.length === 0"
      />
      <v-list
        class="pa-0 "
        v-for="(order, index) in orders"
        :key="index"
        :color="ant"
      >
        <orderComponent class="" :order="order" v-ripple="{ center: true }" />
        <v-divider class="my-0"></v-divider>
      </v-list>
    </v-card-text>
    <!-- <audio autoplay id="notification" muted><source src="@/assets/notifications/order-notification.wav"></audio> -->
  </v-card>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";
import connection from '@/socket/utils';

export default {
  name: "allAvailable",
  components: {
    orderComponent: () =>
      import("@/modules/writersDashboard/available/orderComponent"),
    PlaceHolder: () => import("@/components/core/PlaceHolder"),
  },
  mixins: [colorMxn,validationMxn],
  data() {
    return {
      orders: [],
      model: "",
      chatSocket: null,
      audio: '@/assets/notifications/order-notification.wav',
    };
  },
  computed: {
    title() {
      let title = "All Available Orders";
      switch (this.$route.name) {
        case "all":
          title = "All Available Orders";
          break;
        case "invited":
          title = "Customer Invitations";
          break;
        case "hidden":
          title = "Hidden Orders";
          break;
        case "bidding":
          title = "My Open Bids";
          break;
        case "in-progress":
          title = "My Orders in Progress";
          break;
        case "completed":
          title = "My Completed Ordes";
          break;
        case "cancelled":
          title = "My Cancelled Orders";
          break;
        default:
          title = "All Available Orders";
          break;
      }
      return title;
    }
  },
  mounted() {
    this.getOrders();
    connection.connect();
    this.chatSocket = connection.subscribe(`chat:orders`, this.handleMessageAdd);
  },
  methods: {
    async getOrders() {
      const payload = {
        page: 1,
      };
      const fullPayload = {
        params: payload,
        endpoint: '/writers-all'
      };
      const response = await this.performGetActions(fullPayload);
      this.orders = response.data;
    },
    handleMessageAdd(data) {
      this.orders = [data, ...this.orders];
      // document.getElementById('audio').innerHTML = `<audio autoplay><source src="${this.audio}"></audio>`
      // this.clickAudio();
      this.$orderNotification.play();
    },
    clickAudio() {
      document.getElementById('notification').muted = false;
      document.getElementById('notification').play();
    },
  },

};
</script>
